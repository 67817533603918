import "./intro.css";
import logo from '../../img/logo.png';
// import Don from "../../img/Don.jpg";

const Intro = () => {
  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h1 className="i-name"></h1>
          {/* <h2 className="i-name">Don LeBlanc</h2> */}
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">AI Technologies</div>
              <div className="i-title-item">Human Intelligence</div>
              <div className="i-title-item">Data Extraction</div>
              <div className="i-title-item">Document/Invoice Processing</div>
            </div>
          </div>
          <h3>Unlock Hidden Insights Fast!</h3>
          <p className="i-desc">
          We rapidly extract actionable data from all your documents using advanced AI, so you can focus on strategy, not busywork. Our precision technology distills volumes of text down to the key facts and figures you need to make smarter decisions.
          </p>
          <h3>Save Time and Money!</h3>
          <p>
            Manual data extraction ties up your valuable resources. Our automated solutions get the job done 90% faster and more affordably than conventional methods or AI alone, while ensuring greater accuracy. Spend less time wrangling data, and more time acting on insights.
          </p>
          <h3>The Competitive Edge You seek!</h3>
          <p>
          In today's fast-paced business environment, sound strategy depends on extracting and leveraging intelligence. With our cutting-edge capabilities, we empower teams to outpace the competition. Don't let human limitations hold your company back when AI-powered data extraction is here to accelerate success.
          </p>
        </div>
        <svg
          width="75"
          height="100"
          viewBox="0 0 75 75"
          fill="none"
          stroke="black"
          className="i-scroll"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="scroll">
            <path
              id="Vector"
              d="M40.5 15L34.5 9L28.5 15"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M28.5 24L34.5 30L40.5 24"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group">
              <path
                id="Vector_3"
                d="M9 37.5H60"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <path
              id="Vector_4"
              d="M34.5 27V9"
              stroke-width="2.9895"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group_2">
              <path
                id="Vector_5"
                d="M9 27C9 12.918 20.418 1.5 34.5 1.5C48.5859 1.5 60 12.918 60 27C60 29.8906 60 45.1094 60 48C60 62.082 48.5859 73.5 34.5 73.5C20.418 73.5 9 62.082 9 48C9 45.1094 9 29.8906 9 27Z"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="i-right">
        <div className="i-bg"></div>
        <img src={logo} alt="" className="i-img" />
        {/* <img src={Don} alt="" className="i-img" /> */}
      </div>
    </div>
  );
};

export default Intro;