import "./about.css"
import Sdvosb from "../../img/sdvosb.jpg";
import dataXtract from "../../img/dataextraction.jpg";
// import Me from "../../img/meGeorgia.jpeg";

const About = () => {
  return (
    <div className='a'>
        <div className="a-left">
            <div className="a-card bg"></div>
            <div className="a-card">
            <img
                src={dataXtract}
                alt=""
                className="a-img"
             />
            </div>
        </div>
        <div className="a-right">
        <h1 className="a-title">About Us</h1>
        <h3>The Human Touch with Technology at Scale</h3>
        <p className="a-desc">
        What sets us apart is the way we combine next-gen algorithms and automation with human oversight. Advanced AI extracts information rapidly from documents then our analysts ensure precise, nuanced understanding. Our hybrid approach delivers cost-effective accuracy at speed and scale.
        </p>
        <h3>We Understand Complex Data</h3>
        <p>From convoluted reports to handwritten notes, our machine learning excels at comprehending complexity while statistical methods ensure minimal errors. Our technology-augmented process handles intricacies that defeat human-only analysis. We train our algorithms to extract intelligence tailored to you.</p>
        <h3>Empowering Insights for Good</h3>
        <p className="a-sub">
        We're a veteran-owned small business specializing in unlocking insights from all document types by capitalizing on ScaleHub and FasterAP's unique blend of AI technology and human expertise. Whether your data is trapped in invoices, medical records, government files or historical archives, we rapidly distill accuracy and efficiency.
        </p>
        <div className="a-award">
          <img src={Sdvosb} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">CERTIFIED - Service Disabled Veteran Owned Small Business 2020-2022</h4>
            <p className="a-award-desc">
            "A small business owned and controlled by a Service-Disabled Veteran or Service-Disabled Veterans, as defined in section 3(q) of the Small Business Act (15 U.S.C. 632(q)) and SBA's implementing SDVOSBC Program Regulations (13 C.F.R. 125).""
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About